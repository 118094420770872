import {
  Box,
  Container,
  Stack,
  Text,
  Image,
  Flex,
  Button,
  Heading,
  SimpleGrid,
  useColorModeValue,
  Progress,
  useToast,
  Divider,
  useDisclosure,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Icon,
  Select,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import ProductListing from "./component/productListing";
import {
  buyOrder,
  generateOrder,
  getOrderInfo,
  rateOrder,
} from "../../api/order";
import AccountBalance from "./component/AccountBalance";
import bookingImage from "../../assets/HollymoviesGif.gif";
import ConfirmationModal from "./component/confirmationModal";
import { FaStar } from "react-icons/fa";
import NegativeBalance from "./component/negativeBalance";

export default function Order() {
  const toast = useToast();

  function getOrderInfoInit() {
    try {
      setLoading(true);
      getOrderInfo().then((data) => {
        setMovieDetails(data);
        setIsBalanceNegative(data.negative_amount);
        setIsAmountGreaterThan30(data.wallet_amount >= 30 ? true : false)
        console.log("waiz", parseFloat(data.negative_amount));
        console.log("waiz", data);
        movieStatus(data.currentSession.ratedMovies[0]?.rating?.status);
        setLoading(false);
      });
      // setMovieDetails(getOrderInfoParsing(res));
    } catch (error) {
      console.log(error);
      setLoading(false);
      toast({
        title: "Something Went Wrong",
        description: "Contact Support",
        status: "error",
        position: "top",
      });
    }
  }

  function movieStatus(status) {
    if (!status) {
      setCurrentMovieStatus("Generate");
    } else {
      setCurrentMovieStatus("Rate");
    }
  }

  useEffect(() => {
    getOrderInfoInit();
  }, []);
  const [loading, setLoading] = useState(false);
  const [currentMovieStatus, setCurrentMovieStatus] = useState(true);
  const [movieDetails, setMovieDetails] = useState(false);
  const testColor = useColorModeValue("gray.900", "gray.400");
  const btnBgColor = useColorModeValue("blue.600", "blue.700");
  const btnColor = useColorModeValue("white", "gray.900");
  const dividerColor = useColorModeValue("gray.200", "gray.600");
  const headingColor = useColorModeValue("yellow.500", "yellow.300");
  const [hover, setHover] = useState(null);
  const [rating, setRating] = useState(0);
  const [Comment, setComment] = useState("");
  const [isBalanceNegative, setIsBalanceNegative] = useState(false);
  const [autoGenerate, setAutoGenerate] = useState(false);
  const [isAmountGreaterThan30, setIsAmountGreaterThan30] = useState(true)

  const handleSubmitForBuyAndRate = async (e) => {
    let res;
    setLoading(true);
    if (rating === 0 || Comment === "") {
      toast({
        title: "Please Fill All Feilds",
        status: "warning",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
      setLoading(false);
      return;
    }
    try {
      await buyOrder();
      res = await rateOrder(Comment, rating)
      console.log(`daniyal`, res.data.autoGenerate)

      setRating(0);
      setComment("");
      toast({
        title: "Rating submitted.",
        description: "Thank you for your feedback!",
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
      setLoading(false);
      if (res.data.autoGenerate) {
        handleSubmit(undefined, true)
        setAutoGenerate(true);
      } else {
        setAutoGenerate(false);
        getOrderInfoInit();
      }
    } catch (error) {
      toast({
        title: "An Error Occurerd",
        description:
          error.message ||
          "There was an error submitting your rating. Please try again later.",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
      if (parseInt(error?.balance) < 0) {
        getOrderInfoInit();
      }
      setLoading(false);
    }
  };

  async function handleSubmit(e, autoCall) {
    let res, title;
    if (currentMovieStatus === "Generate" || autoCall) {
      try {
        setLoading(true);
        res = await generateOrder();
        title = "Movie Generated";
        setLoading(false);
        toast({
          title: title,
          description: "Thank you",
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "top",
        });
        getOrderInfoInit();
        console.log(res);
      } catch (error) {
        console.log(error);
        setLoading(false);
        toast({
          title: "Something Went Wrong",
          description: error.message || "Contact Support",
          status: "error",
          position: "top",
        });
      }
    } else {
      handleSubmitForBuyAndRate(e);
    }
  }

  return (
    <>
      {movieDetails ? (
        <Container maxW={"100%"} color={"gray.300"}>
          <SimpleGrid
            columns={{ base: 1, lg: 2 }}
            spacing={{ base: 8, md: 10 }}
            py={{ base: 18, md: 24 }}
          >
            <Flex>
              <Image
                rounded={"md"}
                alt={"product image"}
                src={
                  movieDetails.currentSession?.ratedMovies[0]?.movie_image ||
                  bookingImage
                }
                boxSize="100px"
                objectFit="fit"
                align={"center"}
                w={"100%"}
                h={{ base: "100%", sm: "400px", lg: "500px" }}
              />
            </Flex>
            {parseFloat(isBalanceNegative) >= 0 ? (
              <Stack spacing={{ base: 6, md: 10 }} maxWidth={"70%"}>
                {currentMovieStatus === "Generate" ? (
                  <AccountBalance
                    totalProfit={movieDetails.currentSession.total_profit}
                    totalBalance={movieDetails.wallet_amount}
                  />
                ) : (
                  <ProductListing
                    data={movieDetails.currentSession.ratedMovies[0]}
                  />
                )}
                <Box con>
                  <Heading color={headingColor}>Optimization</Heading>
                  <Divider borderColor={dividerColor} mt={4} size={"full"} />
                  <Progress
                    mt={5}
                    borderRadius={10}
                    value={parseInt(
                      (movieDetails.currentSession.current_index /
                        movieDetails.tier.tier_limit) *
                      100
                    )}
                  />
                  <Text
                    fontWeight={500}
                    fontSize={"lg"}
                    mt={3}
                    color={"gray.300"}
                  >
                    {`${movieDetails.currentSession.current_index} / ${movieDetails.tier.tier_limit}`}
                  </Text>
                </Box>
                {currentMovieStatus === "Rate" ? (
                  <Box con>
                    <Heading color={headingColor}>Rating</Heading>
                    <Divider borderColor={dividerColor} mt={4} size={"full"} />
                    <FormControl>
                      <FormLabel>Rating</FormLabel>
                      <HStack>
                        {[...Array(5)].map((_, index) => {
                          const starValue = index + 1;

                          return (
                            <Icon
                              as={FaStar}
                              key={index}
                              boxSize={8}
                              color={
                                starValue <= (hover || rating)
                                  ? "yellow.400"
                                  : "gray.300"
                              }
                              onMouseEnter={() => setHover(starValue)}
                              onMouseLeave={() => setHover(null)}
                              onClick={() => {
                                setRating(starValue);
                              }}
                              cursor="pointer"
                            />
                          );
                        })}
                      </HStack>
                    </FormControl>
                    <FormControl mt={4}>
                      <FormLabel>Comment</FormLabel>
                      <Select
                        placeholder="Select an option"
                        value={Comment}
                        onChange={(e) => setComment(e.target.value)}
                        color={"white"} // Sets the default text color of the Select
                        bg={"#1A202C"} // Optionally set a background color for the Select dropdown
                        _placeholder={{ color: "white" }} // Placeholder text color
                        sx={{
                          option: {
                            color: "white", // Sets the color of the options text to white
                            backgroundColor: "#1A202C", // Optional: background for the options
                          },
                        }}
                      >
                        <option value="Very Nice Movie"> A blend of strong performances and solid direction.</option>
                        <option value="Wonderful Movie">A story that leaves an impact, no matter the genre.</option>
                        <option value="Average Movie">Visually stunning and emotionally engaging.</option>
                        <option value="Memorable Movie">Packed with memorable moments and great pacing.</option>
                        <option value="Entertaining Movie">A film that entertains while making you think.</option>
                        <option value="Relaxing Movie">Perfect for a relaxing movie night.</option>
                        <option value="Balanced Movie">A solid balance of drama, action, and heart.</option>
                        <option value="Hooked Movie">A film that draws you in and keeps you hooked.</option>
                        <option value="Bad Movie">I don't recommend this movie</option>

                      </Select>
                    </FormControl>
                  </Box>
                ) : (
                  <></>
                )}
                {(isAmountGreaterThan30 || currentMovieStatus !== "Generate") && <Button
                  rounded={"20px"}
                  w={"50%"}
                  size={"lg"}
                  py={"0"}
                  bg={btnBgColor}
                  color={btnColor}
                  textTransform={"uppercase"}
                  _hover={{
                    transform: "translateY(2px)",
                    boxShadow: "lg",
                    bg: `gray.300`,
                    color: "blue.900",
                  }}
                  onClick={handleSubmit}
                  isLoading={loading}
                >
                  {currentMovieStatus}
                </Button>}
              </Stack>
            ) : (
              <>
                <Stack spacing={{ base: 6, md: 10 }} maxWidth={"70%"}>
                  <NegativeBalance
                    totalProfit={movieDetails.currentSession.total_profit}
                    totalBalance={movieDetails.negative_amount}
                  />

                  <Box con>
                    <Heading color={headingColor}>Optimization</Heading>
                    <Divider borderColor={dividerColor} mt={4} size={"full"} />
                    <Progress
                      mt={5}
                      borderRadius={10}
                      value={parseInt(
                        (movieDetails.currentSession.current_index /
                          movieDetails.tier.tier_limit) *
                        100
                      )}
                    />
                    <Text
                      fontWeight={500}
                      fontSize={"lg"}
                      mt={3}
                      color={"gray.300"}
                    >
                      {`${movieDetails.currentSession.current_index} / ${movieDetails.tier.tier_limit}`}
                    </Text>
                  </Box>
                </Stack>
              </>
            )}
          </SimpleGrid>
        </Container>
      ) : (
        <></>
      )}
    </>
  );
}
