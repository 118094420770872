export function renderTawkTo() {
    const username = localStorage.getItem("userName"); // Replace with your local storage key if different

    // Set up Tawk_API object and visitor details
    window.Tawk_API = window.Tawk_API || {};
    window.Tawk_API.visitor = {
      name: username || "Guest", // Default to "Guest" if no username
    };
  const script = document.createElement("script");
  script.async = true;
  // Set the full Tawk.to script content here
  script.innerHTML = `
        var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
        (function(){
        var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
        s1.async=true;
        s1.src='https://embed.tawk.to/673b7f112480f5b4f59ff8fd/1id06gne3';
        s1.charset='UTF-8';
        s1.setAttribute('crossorigin','*');
        s1.setAttribute('name',localStorage.getItem("userName") || "");
        s0.parentNode.insertBefore(s1,s0);
        })();
    `;
  document.body.appendChild(script);
}


export const removeTawkTo = () => {
    const tawkScript = document.querySelector("script[src*='tawk.to']");
    if (tawkScript) {
      tawkScript.remove();
    }
    const tawkIframe = document.querySelector("iframe[src*='tawk.to']");
    if (tawkIframe) {
      tawkIframe.remove();
    }
};
